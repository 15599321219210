.mf83710 {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}
.mf8371q {
  flex-grow: 1;
}
@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/40137990ffaf4190-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/26f6b6879014f014-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/5af7c10b9c79308a-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/38834082e21e68dd-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/6ff86ad3e45d49c5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/d38904e102aa6857-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/b80b5ea97fb5190f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/96b390397cbcc229-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/17485057b4249682-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/dcbcd7cfabeac548-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/7002a8d12e58ff08-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaExtended_412abd';
src: url(/_next/static/media/6a350cc66da0503a-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: italic;
}@font-face {font-family: '__gtAmericaExtended_Fallback_412abd';src: local("Arial");ascent-override: 81.61%;descent-override: 21.05%;line-gap-override: 0.00%;size-adjust: 122.54%
}.__className_412abd {font-family: '__gtAmericaExtended_412abd', '__gtAmericaExtended_Fallback_412abd'
}.__variable_412abd {--gtAmericaExtended: '__gtAmericaExtended_412abd', '__gtAmericaExtended_Fallback_412abd'
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/fc78fda5a9cdfd85-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/34f2f5a4e3808ea8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/9d8c7fdfedd7b6f1-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/a2aed97710a96c6f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/c30ebf1bc73ea689-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/a84420dac2882a66-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/66a3641352af1e25-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/c274c906ceb47473-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/9c38e3b220899666-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/3a6d74077258b6eb-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/08412f37568e07d4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/9daa9a004704760f-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/31e4016672c76e52-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/3be83d716ed174a6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/090e83e8d67f2f71-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/8d7d7c4fb34c98ae-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/7dbcfd5b0ad82903-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaMono_2c6e20';
src: url(/_next/static/media/a5ff8f3146afcc8e-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__gtAmericaMono_Fallback_2c6e20';src: local("Arial");ascent-override: 73.60%;descent-override: 18.99%;line-gap-override: 0.00%;size-adjust: 135.87%
}.__className_2c6e20 {font-family: '__gtAmericaMono_2c6e20', '__gtAmericaMono_Fallback_2c6e20'
}.__variable_2c6e20 {--gtAmericaMono: '__gtAmericaMono_2c6e20', '__gtAmericaMono_Fallback_2c6e20'
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/4a341e298e78dc2c-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/47bd3325f819582c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/0cd97a782ebae49d-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/feaacb05aceb999f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/3d291e3524233af7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/3e1a536d77b2d4d6-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/86836c955de73572-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/01736d1f283c528f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/c8be55837bf1d774-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/dd08198ee4ccca2b-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/4bf46a6f31b0d525-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__gtAmericaStandard_0d05d7';
src: url(/_next/static/media/b09e99fa96f2848b-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__gtAmericaStandard_Fallback_0d05d7';src: local("Arial");ascent-override: 98.13%;descent-override: 25.32%;line-gap-override: 0.00%;size-adjust: 101.90%
}.__className_0d05d7 {font-family: '__gtAmericaStandard_0d05d7', '__gtAmericaStandard_Fallback_0d05d7'
}.__variable_0d05d7 {--gtAmericaStandard: '__gtAmericaStandard_0d05d7', '__gtAmericaStandard_Fallback_0d05d7'
}

